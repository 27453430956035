<template>
    <div class="d-flex justify-content-center">
        <h3>You have no permissions to access this page please contact admin</h3>
    </div>
</template>
<script>
import store from '@/store';
export default {
mounted(){
    store.state.loading=false;
}
}
</script>